export interface LootBoxOpenResult {
  amount: number;
  rewardType: ItemType;
  rewardId: number;
  multiplyByShirine?: boolean;
}
export interface SlotResult {
  id: number;
  rewardType: ItemType;
  rewardId: number;
}
export enum LootboxId {
  Regular,
  Rare,
  Legendary,
  Keys,
}

export enum ItemType {
  timeBoosts,
  lootBox,
  farmResource,
  universalShard,
  heroShard,
  keyShard,
}

export enum UniversalShard {
  heroCommonShard,
  heroRareShard,
  heroLegendaryShard,
  Rainbow,
}
export enum HeroShard {
  firstHero,
}
export enum TimeBoosts {
  boost1m,
  boost5m,
  boost15m,
  boost1h,
  boost8h,
}
