import { useTranslation } from 'react-i18next';
import { StatsBlock } from './components';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { Resources } from '../../enums/resources';
import React, { useEffect, useRef, useState } from 'react';
import { AllTimeKitsune, getAllTimeKitsune } from '../../endpoints/farmMock';
import { useTelegram } from '../../hooks/useTelegram';
import { AnimatePresence, motion } from 'framer-motion';
import { Tooltip } from 'react-tooltip';
import { PopupButton } from '../../components/PopupButton';
import { useNavigate } from 'react-router-dom';
import { useSessionStorage } from '@uidotdev/usehooks';

const ToolTipIcon = () => {
  return (
    <div className="bg-[#4181B8] rounded-full w-[16px] h-[16px] border border-[#043D6D] flex items-center justify-center">
      <div className="w-[5px] h-[11px]">
        <img
          className="w-full h-full block"
          src={require('../../assets/images/heroes/icons/info.png')}
          alt="Info Icon"
        />
      </div>
    </div>
  );
};

type ToolTipBalanceType = {
  isOpen: boolean;
  text: string;
  onClick: () => void;
  id: string;
};
const ToolTipBalance = ({ isOpen, text, onClick, id }: ToolTipBalanceType) => {
  if (isOpen) {
    return (
      <Tooltip
        isOpen={true}
        id={id}
        place="top-start"
        style={{
          backgroundColor: '#ffffff',
          color: '#000000',
          border: '1px solid #000000',
          borderRadius: '8px',
          maxWidth: '300',
          padding: '8px',
          wordWrap: 'break-word',
          marginLeft: '-37px',
        }}
        clickable={true}
        classNameArrow="ml-[37px]"
        opacity={1}
      >
        <div className="flex items-center gap-2">
          <div className="w-[180px] text-center text-black text-xs font-black  leading-3">{text}</div>
          <PopupButton width="72px" type={'blue'} onClick={onClick} className="text-white">
            Go
          </PopupButton>
        </div>
      </Tooltip>
    );
  }
  return null;
};

enum TooltipNames {
  Main = 'main',
  Rank = 'rank',
  Friends = 'friends',
  HeroTier = 'heroTier',
  Sbt = 'sbt',
}

type TooltipState = {
  [key in TooltipNames]: boolean;
};
export const Balance = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [sbtModal, setSbtModal] = useSessionStorage<boolean>('sbtModal', false);
  const resources = useSelector((state: RootState) => state.resources.resources);
  const { userId } = useTelegram();

  const [allkitsuData, setAllkitsuData] = useState<AllTimeKitsune>({
    allTimeKitsu: 0,
    boughtAndEarned: 0,
    friendsMultiplier: 0,
    nftMultiplier: 0,
    rankMultiplier: 0,
    sbtMultiplier: 0,
  });

  useEffect(() => {
    const getAllkitsu = async () => {
      const result = await getAllTimeKitsune({
        clientId: userId,
      });

      if (result) {
        setAllkitsuData(result);
      }
    };

    getAllkitsu();
  }, []);

  const [openStats, setOpenStats] = useState(false);

  const [tooltips, setTooltips] = useState<TooltipState>({
    [TooltipNames.Main]: false,
    [TooltipNames.Rank]: false,
    [TooltipNames.Friends]: false,
    [TooltipNames.HeroTier]: false,
    [TooltipNames.Sbt]: true,
  });
  const mainToolTipRef = useRef<HTMLDivElement>(null);
  const rankToolTipRef = useRef<HTMLDivElement>(null);
  const friendsToolTipRef = useRef<HTMLDivElement>(null);
  const heroTierToolTipRef = useRef<HTMLDivElement>(null);
  const sbtToolTipRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    setOpenStats(!openStats);
  };

  const variants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
  };

  useEffect(() => {
    const handleClick = (e: Event) => {
      if (
        (!mainToolTipRef.current || !mainToolTipRef.current.contains(e.target as Node)) &&
        (!rankToolTipRef.current || !rankToolTipRef.current.contains(e.target as Node)) &&
        (!friendsToolTipRef.current || !friendsToolTipRef.current.contains(e.target as Node)) &&
        (!heroTierToolTipRef.current || !heroTierToolTipRef.current.contains(e.target as Node)) &&
        (!sbtToolTipRef.current || !sbtToolTipRef.current.contains(e.target as Node))
      ) {
        setTooltips({
          main: false,
          rank: false,
          friends: false,
          heroTier: false,
          sbt: false,
        });
      }
    };
    document.addEventListener('mousedown', handleClick);
    document.addEventListener('wheel', handleClick);
    document.addEventListener('touchmove', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
      document.removeEventListener('wheel', handleClick);
      document.removeEventListener('touchmove', handleClick);
    };
  }, []);

  const handleTooltipToggle = (tooltipName: TooltipNames) => {
    setTooltips((prevTooltips) => {
      const newTooltips: TooltipState = {
        main: false,
        rank: false,
        friends: false,
        heroTier: false,
        sbt: false,
      };

      newTooltips[tooltipName] = !prevTooltips[tooltipName];

      return newTooltips;
    });
  };

  function defineCountString(count: number) {
    if (Math.floor(count / 1000000) > 0) {
      const result = Math.round(count / 100000) / 10;
      return result % 1 === 0 ? result.toFixed(0) + 'M' : result.toFixed(1) + 'M';
    } else if (Math.floor(count / 1000) > 0) {
      const result = Math.round(count / 100) / 10;
      return result % 1 === 0 ? result.toFixed(0) + 'K' : result.toFixed(1) + 'K';
    } else {
      return count % 1 === 0 ? count.toFixed(0) : count.toFixed(2);
    }
  }

  return (
    <div>
      <div className="text-center text-white text-2xl font-normal leading-normal font-['Londrina_Solid']">
        {t('profile.balance')}
      </div>
      <div className="gap-4 flex flex-col mt-2">
        <div className="flex flex-col bg-stone-900 rounded shadow-inner border border-stone-700">
          <div className="flex items-center text-center justify-between">
            <div
              className="p-4 w-1/2"
              onClick={() => {
                handleTooltipToggle(TooltipNames.Main);
              }}
            >
              <div className="flex items-center">
                <p className="text-base leading-none font-bold mr-1 text-custom-sand">{t('profile.kitsune')}</p>
                <div
                  className="bottom-2 inset-x-0 flex justify-center items-center"
                  data-tooltip-id="totalKitsuTip"
                  ref={mainToolTipRef}
                >
                  <ToolTipIcon />
                  {tooltips.main && (
                    <Tooltip
                      isOpen={true}
                      id="totalKitsuTip"
                      place="top-start"
                      style={{
                        backgroundColor: '#ffffff',
                        color: '#000000',
                        border: '1px solid #000000',
                        borderRadius: '8px',
                        padding: '8px',
                        textAlign: 'center',
                        maxWidth: '200px',
                        wordWrap: 'break-word',
                        marginLeft: '-8px',
                        marginTop: '-12px',
                      }}
                      clickable={true}
                      classNameArrow="ml-[8px]"
                      opacity={1}
                    >
                      <div className="flex flex-col">
                        <div className="w-[180px] text-center text-black text-xs font-black leading-3">
                          {t('profile.tooltips.allTime')}
                        </div>
                      </div>
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
            <div onClick={handleClick} className="p-4 w-1/2">
              <div className="flex justify-end items-center gap-2">
                <div className="flex items-center gap-x-1">
                  <div className="w-7 h-7">
                    <img src={require('../../assets/images/lobby_ico.png')} />
                  </div>
                  <p className="text-right text-white text-[28px] font-black leading-7 ">
                    {defineCountString(allkitsuData.allTimeKitsu)}
                  </p>
                </div>
                <div className="cursor-pointer">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className={`transition-transform duration-300 ${!openStats ? 'transform rotate-180' : ''}`}
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M20 14.995L12 6.995L4 14.995L6 17L12 10.995L18 17L20 14.995Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          {openStats ? <div className="h-[4px] bg-[#302925] mt-2"></div> : null}

          <AnimatePresence>
            {openStats && (
              <>
                <motion.div
                  initial="hidden"
                  animate="visible"
                  exit="exit"
                  variants={variants}
                  transition={{ duration: 0.5 }}
                  className="flex flex-col p-4"
                >
                  <div className="flex items-center justify-between">
                    <div className="text-white text-[15px] font-extrabold leading-[15px]">
                      {t('profile.multipliers.title')}
                    </div>
                    <div className="flex items-center gap-x-1">
                      <div className="w-4 h-4">
                        <img src={require('../../assets/images/lobby_ico.png')} alt="Lobby Icon" />
                      </div>
                      <div className="text-white text-base font-black leading-none">
                        {defineCountString(allkitsuData.boughtAndEarned)}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col pt-3">
                    <div className="flex justify-between items-center">
                      <div
                        className="flex items-center p-2"
                        onClick={() => {
                          handleTooltipToggle(TooltipNames.Rank);
                        }}
                      >
                        <p className="text-white text-sm font-bold leading-[14px] mr-1">
                          {t('profile.multipliers.rankNft')}
                        </p>
                        <div
                          className="bottom-2 inset-x-0 flex justify-center items-center"
                          data-tooltip-id="rankToolTip"
                          ref={rankToolTipRef}
                        >
                          <ToolTipIcon />
                          <ToolTipBalance
                            isOpen={tooltips.rank}
                            text={t('profile.tooltips.rankNft')}
                            onClick={() => {
                              navigate('/rank');
                            }}
                            id="rankToolTip"
                          />
                        </div>
                      </div>
                      <div className="text-right text-[#3adc96] text-[15px] font-extrabold leading-[15px]">
                        {allkitsuData.rankMultiplier * 100}%
                      </div>
                    </div>
                    <div className="flex justify-between items-center bg-[#362c28]/50">
                      <div
                        className="flex items-center p-2"
                        onClick={() => {
                          handleTooltipToggle(TooltipNames.Friends);
                        }}
                      >
                        <p className="text-white text-sm font-bold leading-[14px] mr-1">
                          {t('profile.multipliers.FriendsNft')}
                        </p>
                        <div
                          className="bottom-2 inset-x-0 flex justify-center items-center"
                          data-tooltip-id="friendsToolTip"
                          ref={friendsToolTipRef}
                        >
                          <ToolTipIcon />
                          <ToolTipBalance
                            isOpen={tooltips.friends}
                            text={t('profile.tooltips.FriendsNft')}
                            onClick={() => {
                              navigate('/friends');
                            }}
                            id="friendsToolTip"
                          />
                        </div>
                      </div>
                      <div className="text-right text-[#3adc96] text-[15px] font-extrabold leading-[15px]">
                        {allkitsuData.friendsMultiplier * 100}%
                      </div>
                    </div>
                    <div className="flex justify-between items-center">
                      <div
                        className="flex items-center p-2"
                        onClick={() => {
                          handleTooltipToggle(TooltipNames.HeroTier);
                        }}
                      >
                        <p className="text-white text-sm font-bold leading-[14px] mr-1">
                          {t('profile.multipliers.heroNft')}
                        </p>
                        <div
                          className="bottom-2 inset-x-0 flex justify-center items-center"
                          data-tooltip-id="heroTierToolTip"
                          ref={heroTierToolTipRef}
                        >
                          <ToolTipIcon />
                          <ToolTipBalance
                            isOpen={tooltips.heroTier}
                            text={t('profile.tooltips.heroNft')}
                            onClick={() => {
                              navigate('/heroes');
                            }}
                            id="heroTierToolTip"
                          />
                        </div>
                      </div>
                      <div className="text-right text-[#3adc96] text-[15px] font-extrabold leading-[15px]">
                        {allkitsuData.nftMultiplier * 100}%
                      </div>
                    </div>
                    <div className="flex justify-between items-center bg-[#362c28]/50">
                      <div
                        className="flex items-center p-2"
                        onClick={() => {
                          handleTooltipToggle(TooltipNames.Sbt);
                        }}
                      >
                        <p className="text-white text-sm font-bold leading-[14px] mr-1">
                          {t('profile.multipliers.sbtNft')}
                        </p>
                        <div
                          className="bottom-2 inset-x-0 flex justify-center items-center"
                          data-tooltip-id="sbtToolTip"
                          ref={sbtToolTipRef}
                        >
                          <ToolTipIcon />
                          <ToolTipBalance
                            isOpen={tooltips.sbt}
                            text={t('profile.tooltips.sbtNft')}
                            onClick={() => {
                              navigate('/island');
                              setSbtModal(true);
                            }}
                            id="sbtToolTip"
                          />
                        </div>
                      </div>
                      <div className="text-right text-[#3adc96] text-[15px] font-extrabold leading-[15px]">
                        {allkitsuData.sbtMultiplier * 100}%
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center justify-between pt-4">
                    <div className="text-white text-[15px] font-extrabold leading-[15px]">
                      {t('profile.multipliers.total')}
                    </div>
                    <div className="text-white text-base font-black leading-none">
                      {(allkitsuData.friendsMultiplier +
                        allkitsuData.nftMultiplier +
                        allkitsuData.rankMultiplier +
                        allkitsuData.sbtMultiplier) *
                        100}
                      %
                    </div>
                  </div>
                </motion.div>
              </>
            )}
          </AnimatePresence>
        </div>

        <StatsBlock
          textKey="profile.currentBalance"
          value={`${defineCountString(resources[Resources.kitsu]?.value)}`}
        />
      </div>
    </div>
  );
};
