import { useState } from 'react';
import { DisconnectButton } from './components';
import { useTonAddress } from '@tonconnect/ui-react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';
import { PopupButton } from '../../../../../../../../components/PopupButton';
export const WalletInfo = ({ closeWalletInfo }: { closeWalletInfo: () => void }) => {
  const [copied, setCopied] = useState(false);
  const address = useTonAddress();
  const { t } = useTranslation();
  const handleCopyClick = async () => {
    if (!address) return;
    const urlToCopy = address;
    try {
      await navigator.clipboard.writeText(urlToCopy);
      setCopied(true);
      toast.success('Wallet copied to clipboard!');
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy: ', err);
      toast.error('Failed to copy URL.');
    }
  };
  function formatAddress(address: string) {
    if (address.length <= 12) {
      return address;
    }
    const start = address.slice(0, 7);
    const end = address.slice(-8);
    return `${start}...${end}`;
  }

  return (
    <div>
      <div className="w-full h-full fixed top-0 left-0 bottom-0 max-w-[550px] mx-auto right-0 z-10 flex items-center justify-center">
        <div className="w-full h-full absolute bg-black opacity-60"></div>
        <div className="absolute inset-x-4 bg-[#554837] border border-[#18191a] rounded-[2px] p-[2px]">
          <img
            src={require('../../../../../../../../assets/images/cards-modal-border.png')}
            className="absolute w-[17.5px] h-[21px] -top-[6px] -left-[5px]"
            alt=""
          />
          <img
            src={require('../../../../../../../../assets/images/cards-modal-border.png')}
            className="absolute w-[17.5px] h-[21px] -bottom-[6.5px] -left-[4.5px] -rotate-90"
            alt=""
          />
          <img
            src={require('../../../../../../../..//assets/images/cards-modal-border.png')}
            className="absolute w-[17.5px] h-[21px] -bottom-[5.5px] -right-[4.5px] -rotate-180"
            alt=""
          />
          <button
            className="absolute z-10 w-12 h-12 -top-4 -right-4 flex items-center justify-center"
            onClick={closeWalletInfo}
          >
            <img
              src={require('../../../../../../../../assets/images/shop-modal-close.png')}
              className="w-8 h-8"
              alt=""
            />
          </button>
          <div className="w-full  bg-[#242520] border border-[#18191a] rounded-[2px] p-3 shadow-inner-sm-white">
            <div className="relative w-full bg-[#201b18] rounded-[1px] p-3 pt-7 shadow-inner-sm-black">
              <div className="absolute top-[-28px] left-0 right-0 flex justify-self-center">
                <div className="min-w-[120px] bg-[#847a70] border border-[#18191a] rounded-[2px] p-[1px] mx-auto">
                  <div
                    className="bg-[#351d1e] text-center font-extrabold text-white text-xl p-[7px] leading-none rounded-[1px]
                        border border-[#18191a] shadow-inner-sm-black uppercase"
                  >
                    Wallet
                  </div>
                </div>
              </div>
              <div className="flex flex-col justify-center gap-4">
                <div className="text-center text-[#E1C78C] text-[28px] font-extrabold leading-9 break-words whitespace-normal">
                  {t('profile.walletinfo.walletConnected')}
                </div>

                <div className="text-center text-[#FFEFCB] text-[15px] font-semibold text-stroke-small">
                  {t('profile.walletinfo.disconnectOrCopy')}
                </div>

                <div className="px-[30px] mb-5">
                  <div className=" bg-[#1C1918] rounded shadow-inner border border-stone-700">
                    <div className="flex justify-between items-center px-2 py-2">
                      <div className="text-white text-base font-normal leading-[18px]">{formatAddress(address)}</div>
                      <PopupButton size="small" type={'blue'} onClick={handleCopyClick} width="19px" height="19px">
                        <div className="absolute top-0 right-0 bottom-0 left-0 flex justify-center items-center">
                          {copied ? (
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10S15.523 0 10 0zm5.707 7.293l-6 6a1 1 0 01-1.414 0l-3-3a1 1 0 011.414-1.414L9 11.586l5.293-5.293a1 1 0 111.414 1.414z"
                                fill="white"
                              />
                            </svg>
                          ) : (
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 32 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g filter="url(#filter0_d_5771_25439)">
                                <path
                                  d="M24 9V27C24 27.2652 23.8946 27.5196 23.7071 27.7071C23.5196 27.8946 23.2652 28 23 28H5C4.73478 28 4.48043 27.8946 4.29289 27.7071C4.10536 27.5196 4 27.2652 4 27V9C4 8.73478 4.10536 8.48043 4.29289 8.29289C4.48043 8.10536 4.73478 8 5 8H23C23.2652 8 23.5196 8.10536 23.7071 8.29289C23.8946 8.48043 24 8.73478 24 9ZM27 4H9C8.73478 4 8.48043 4.10536 8.29289 4.29289C8.10536 4.48043 8 4.73478 8 5C8 5.26522 8.10536 5.51957 8.29289 5.70711C8.48043 5.89464 8.73478 6 9 6H26V23C26 23.2652 26.1054 23.5196 26.2929 23.7071C26.4804 23.8946 26.7348 24 27 24C27.2652 24 27.5196 23.8946 27.7071 23.7071C27.8946 23.5196 28 23.2652 28 23V5C28 4.73478 27.8946 4.48043 27.7071 4.29289C27.5196 4.10536 27.2652 4 27 4Z"
                                  fill="#FFEFCB"
                                />
                                <path
                                  d="M24.25 27V9C24.25 8.66848 24.1183 8.35054 23.8839 8.11612C23.6495 7.8817 23.3315 7.75 23 7.75H5C4.66848 7.75 4.35054 7.8817 4.11612 8.11612C3.8817 8.35054 3.75 8.66848 3.75 9V27C3.75 27.3315 3.8817 27.6495 4.11612 27.8839C4.35054 28.1183 4.66848 28.25 5 28.25H23C23.3315 28.25 23.6495 28.1183 23.8839 27.8839C24.1183 27.6495 24.25 27.3315 24.25 27ZM27 3.75H9C8.66848 3.75 8.35054 3.8817 8.11612 4.11612C7.8817 4.35054 7.75 4.66848 7.75 5C7.75 5.33152 7.8817 5.64946 8.11612 5.88388C8.35054 6.1183 8.66848 6.25 9 6.25H25.75V23C25.75 23.3315 25.8817 23.6495 26.1161 23.8839C26.3505 24.1183 26.6685 24.25 27 24.25C27.3315 24.25 27.6495 24.1183 27.8839 23.8839C28.1183 23.6495 28.25 23.3315 28.25 23V5C28.25 4.66848 28.1183 4.35054 27.8839 4.11612C27.6495 3.8817 27.3315 3.75 27 3.75Z"
                                  stroke="#1D3874"
                                  strokeWidth="1"
                                />
                              </g>
                              <defs>
                                <filter
                                  id="filter0_d_5771_25439"
                                  x="3"
                                  y="2.8"
                                  width="25.5"
                                  height="25.7"
                                  filterUnits="userSpaceOnUse"
                                  colorInterpolationFilters="sRGB"
                                >
                                  <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                  <feColorMatrix
                                    in="SourceAlpha"
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    result="hardAlpha"
                                  />
                                  <feOffset dx="-0.5" dy="-0.7" />
                                  <feComposite in2="hardAlpha" operator="out" />
                                  <feColorMatrix
                                    type="matrix"
                                    values="0 0 0 0 0.113725 0 0 0 0 0.219608 0 0 0 0 0.454902 0 0 0 1 0"
                                  />
                                  <feBlend
                                    mode="normal"
                                    in2="BackgroundImageFix"
                                    result="effect1_dropShadow_5771_25439"
                                  />
                                  <feBlend
                                    mode="normal"
                                    in="SourceGraphic"
                                    in2="effect1_dropShadow_5771_25439"
                                    result="shape"
                                  />
                                </filter>
                              </defs>
                            </svg>
                          )}
                        </div>
                      </PopupButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center pt-5 pb-3">
              <DisconnectButton />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer className={'fixed top-[70px] z-40'} />
    </div>
  );
};
