// Inventory.tsx
import React, { useEffect, useState } from 'react';
import { CardItem } from './components/CardItem';
import { HandleBackButton } from '../../layout/components/HeaderCave/components';
import { getMyLootboxes, openLootbox } from '../../endpoints/lootBoxEndpoints';
import { useTelegram } from '../../hooks/useTelegram';
import { HeroShard, ItemType, LootboxId, TimeBoosts, UniversalShard } from '../../interfaces/lootBotx';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../app/store';
import { BoostsType, fetchActiveBoosts } from '../../app/features/inventorySlice';
import { OpenLootBox, QuantitySelector } from './components';
import { PopupButton } from '../../components/PopupButton';
import { useTranslation } from 'react-i18next';
import { getHeroesShards } from '../../endpoints/heroEndpoints';
import { heroShardMap, lootBoxMap, timeBoostsMap, universalShardMap } from '../../utils/mapping';
import { DisplayData, handleLootBoxResult } from '../../utils/lootBoxHandler';
import { Resources } from '../../enums/resources';
import { useNavigate } from 'react-router-dom';
import { useSessionStorage } from '@uidotdev/usehooks';

// types.ts
export enum CategoryItem {
  Heroes,
  Items,
}

export interface BaseInventoryItem {
  id: string;
  name: string;
  description: string;
  type: ItemType;
  category: CategoryItem;
  amount: number;
  icon: string;
}

export interface LootBoxItem extends BaseInventoryItem {
  type: ItemType.lootBox;
  lootBoxRarity: LootboxId;
}

export interface BoostItem extends BaseInventoryItem {
  bonusId: number;
  type: ItemType.timeBoosts;
  boostsType: BoostsType;
  duration: number;
}

export interface HeroesItem extends BaseInventoryItem {
  rarity: UniversalShard | HeroShard;
}

export type InventoryItem = LootBoxItem | BoostItem | HeroesItem | BaseInventoryItem;

interface Tab {
  id: CategoryItem;
  label: string;
}

interface InventoryProps {
  closeDeck: () => void;
}

export const Inventory: React.FC<InventoryProps> = ({ closeDeck }) => {
  const [activeTab, setActiveTab] = useState<CategoryItem>(CategoryItem.Items);
  const { userId } = useTelegram();
  const [data, setData] = useState<InventoryItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<InventoryItem | null>(null);
  const [isManualSelection, setIsManualSelection] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [safeAreaTop, setSafeAreaTop] = useState(() => {
    return sessionStorage.getItem('safeAreaTop') || 0;
  });
  // Визначаємо масив табів
  const tabs: Tab[] = [
    {
      id: CategoryItem.Items,
      label: t(`inventory.category.${CategoryItem.Items}`),
    },
    {
      id: CategoryItem.Heroes,
      label: t(`inventory.category.${CategoryItem.Heroes}`),
    },
  ];

  const boosts = useSelector((state: RootState) => state.inventory.boosts);
  const resources = useSelector((state: RootState) => state.resources.resources);

  const currentKeys = resources.find((v) => v.resourceType === Resources.keys)?.value || 0;

  const [watchedInventoryItems, setWatchedInventoryItems] = useSessionStorage('watchedItems', 0);

  const fetchData = async (type: CategoryItem) => {
    setLoading(true);
    try {
      if (type === CategoryItem.Items) {
        // Замість цього URL вставте ваш API endpoint
        const responseLootboxes = await getMyLootboxes({
          clientId: userId,
        });

        const boostsAction = await dispatch(fetchActiveBoosts(userId));

        if (fetchActiveBoosts.fulfilled.match(boostsAction)) {
          const boostsData = boostsAction.payload;

          //сортування лут боксів по id
          responseLootboxes.sort((a, b) => b.lootBoxId - a.lootBoxId);
          // Маппінг лутбоксів
          const mappedLootBoxes: LootBoxItem[] = responseLootboxes.map((item, index) => ({
            id: `lootBox-${item.lootBoxId}-${index}`,
            type: ItemType.lootBox,
            name: `${lootBoxMap[item.lootBoxId as LootboxId].name} ${
              item.lootBoxId !== 0 ? t('inventory.lootBoxesFullName') : ''
            }`,
            description: t('inventory.lootBoxesDescription'),
            lootBoxRarity: item.lootBoxId,
            category: CategoryItem.Items,
            amount: item.amount,
            icon: lootBoxMap[item.lootBoxId as LootboxId].image,
          }));

          // Маппінг бустів
          const mappedBoosts: BoostItem[] = boostsData.map((item, index) => ({
            id: `boost-${item.bonusId}-${index}`,
            type: ItemType.timeBoosts,
            category: CategoryItem.Items,
            name: timeBoostsMap[item.bonusId as TimeBoosts].name,
            description: timeBoostsMap[item.bonusId as TimeBoosts].description,
            bonusId: item.bonusId,
            boostsType: item.bonusType,
            duration: item.duration,
            amount: item.amount,
            icon: timeBoostsMap[item.bonusId as TimeBoosts].image,
          }));

          if (responseLootboxes && boosts) {
            const lootBoxesTotalAmount = responseLootboxes.reduce((prev, next) => {
              return prev + next.amount;
            }, 0);

            const boostsTotalAmount = boosts.reduce((prev, next) => {
              return prev + next.amount;
            }, 0);

            setWatchedInventoryItems(lootBoxesTotalAmount + boostsTotalAmount);
          }

          setData([...mappedLootBoxes, ...mappedBoosts].filter((item) => item.amount > 0));
        }
      }
      if (type === CategoryItem.Heroes) {
        const responseHeroesShards = await getHeroesShards({
          clientId: userId,
        });
        const mappedHeroesShards: HeroesItem[] = responseHeroesShards.heroShards.map((item, index) => {
          return {
            id: `heroShards-${item.heroId}-${index}`,
            type: ItemType.heroShard,
            name: heroShardMap[item.heroId as HeroShard].name,
            description: heroShardMap[item.heroId as HeroShard].description,
            category: CategoryItem.Heroes,
            amount: item.amount,
            icon: heroShardMap[item.heroId as HeroShard].image,
            rarity: item.heroId,
          };
        });

        const mappedUniversalShards: HeroesItem[] = responseHeroesShards.universalShards.map((item, index) => {
          return {
            id: `universalShards-${item.shardId}-${index}`,
            type: ItemType.universalShard,
            name: universalShardMap[item.shardId as UniversalShard].name,
            description: universalShardMap[item.shardId as UniversalShard].description,
            category: CategoryItem.Heroes,
            amount: item.amount,
            icon: universalShardMap[item.shardId as UniversalShard].image,
            rarity: item.shardId,
          };
        });

        // set key shard
        const keyShard: BaseInventoryItem[] = [
          {
            id: 'key-shard',
            name: t('inventory.heroKey'),
            description: t('inventory.heroKeyDescription'),
            type: ItemType.keyShard,
            category: CategoryItem.Heroes,
            amount: currentKeys,
            icon: require('../../assets/images/keys.png'),
          },
        ];
        if (currentKeys) {
          setData([...keyShard, ...mappedHeroesShards, ...mappedUniversalShards].filter((item) => item.amount > 0));
        } else {
          setData([...mappedHeroesShards, ...mappedUniversalShards].filter((item) => item.amount > 0));
        }
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setData([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isManualSelection && selectedItem) {
      const updatedItem = data.find((item) => item.id === selectedItem.id);
      if (updatedItem) {
        setSelectedItem(updatedItem);
      }
    }
  }, [data]);

  const handleSelectItem = (item: InventoryItem) => {
    setIsManualSelection(true);
    setSelectedItem(item);
  };

  const handleCloseItem = () => {
    setSelectedItem(null);
  };

  useEffect(() => {
    fetchData(activeTab);
    setSelectedItem(null);
    setQuantity(1);
  }, [activeTab]);

  // Функція для фільтрації даних за типом
  const getTabContent = (type: CategoryItem) => {
    if (loading) {
      return <div className="flex items-center justify-center w-full text-2xl text-white pb-12">Loading...</div>;
    }

    if (data.length === 0) {
      return <div className="flex items-center justify-center w-full text-2xl text-white pb-12">Empty</div>;
    }

    return (
      <div className="grid grid-cols-4 gap-4 justify-start">
        {data.map((item) => (
          <CardItem key={item.id} item={item} onSelect={handleSelectItem} selected={item.id === selectedItem?.id} />
        ))}
      </div>
    );
  };

  const [quantity, setQuantity] = useState<number>(1);

  const [rewards, setRewards] = useState<DisplayData[]>([]);
  const [openBoxName, setOpenBoxName] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleUse = async () => {
    if (isProcessing || !selectedItem) {
      return;
    }
    setIsProcessing(true);
    if (selectedItem?.type === ItemType.lootBox) {
      const lootBoxItem = selectedItem as LootBoxItem;
      const result = await openLootbox({
        clientId: userId,
        lootBoxId: lootBoxItem.lootBoxRarity,
        amount: quantity,
      });

      setOpenBoxName(lootBoxItem.name);
      if (result) {
        const displayData: DisplayData[] = result
          .map(handleLootBoxResult)
          .filter((data): data is DisplayData => data !== null);

        setRewards(displayData);
      }
      if (selectedItem.amount === quantity) {
        setSelectedItem(null);
      }
      setQuantity(1);
      setIsManualSelection(false);
      setIsProcessing(false);
    }

    // Реалізуйте логіку використання лутбоксів
    // console.log(`Використано ${quantity} лутбоксів`);
    // Наприклад, викликайте API або оновлюйте стан
  };
  const handleClose = () => {
    setOpenBoxName(null);
    setRewards([]);
    fetchData(activeTab);
  };
  const handleClickUseLink = () => {
    if (selectedItem) {
      if (selectedItem.type === ItemType.keyShard) {
        navigate('/heroes');
        sessionStorage.setItem('openRecruitModal', 'true');
      } else if (selectedItem.type === ItemType.heroShard) {
        navigate('/heroes');
        sessionStorage.setItem('openHeroTierModal', 'true');
        sessionStorage.setItem('shardHeroName', JSON.stringify(selectedItem.name.split(' ')[0]));
      }
    }
  };

  // after selecting an item, reset the quantity
  useEffect(() => {
    setQuantity(1);
  }, [selectedItem]);

  const buttonBaseClass = 'bg-[#242520] rounded-t-sm w-full p-[1px] transition-all duration-300';
  const buttonInnerClass = 'bg-[#242520] rounded-t-sm w-full h-full text-white text-sm leading-none p-3';

  return (
    <div
      className="w-full h-full fixed max-w-[550px] mx-auto z-[57] bg-[#070707]"
      style={{
        top: `${safeAreaTop}px`,
        height: `calc(100% - ${+safeAreaTop}px)`,
      }}
    >
      {/* Основий контент модального вікна */}
      <div className="relative flex justify-start items-center mb-[15px] w-full bg-[#312e2b] border-y-2 border-y-[#574E48] shadow-inner-sm-black">
        {/* Заголовок Inventory */}
        <div className="absolute top-[1px] pl-3">
          <HandleBackButton onClick={closeDeck} />
        </div>
        <div className="text-white text-2xl font-black leading-normal pl-16 text-shadow-black-sm">
          {t('inventory.name')}
        </div>
      </div>
      {/* Таби */}
      <div className="w-full flex justify-center gap-x-2 ">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            className={`${buttonBaseClass} ${activeTab !== tab.id ? 'opacity-60' : ''} `}
            onClick={() => setActiveTab(tab.id)}
          >
            <div className={buttonInnerClass}>{tab.label}</div>
          </button>
        ))}
      </div>

      <div className="w-full bg-[#242520] border border-[#18191a] rounded-[2px] p-3 shadow-inner-sm-white h-[calc(100%-95px)]">
        <div
          className="relative w-full bg-[#201b18] rounded-[1px] p-3 shadow-inner-sm-black overflow-auto h-full"
          style={{
            background: 'radial-gradient(circle, #332B26 0%, #201B18 60%)',
          }}
        >
          {/* Контент табів */}
          <div className="flex-1 relative">
            <div className="overflow-y-auto h-full ">
              {tabs.map((tab) =>
                activeTab === tab.id ? (
                  <div key={tab.id} className="scrollable-element transition-opacity duration-300 p-[2px]">
                    {getTabContent(tab.id)}
                  </div>
                ) : null
              )}
            </div>
            {/* Вікно з описом вибраного елемента */}
          </div>
          {selectedItem && (
            <div className="absolute bottom-0 left-0 right-0 p-4 z-10 h-[260px]">
              <div className="w-full h-[3px] bg-[#3b332e]"></div>
              <div className="flex justify-between items-center mb-2 pt-2">
                <h2 className="text-xl font-bold text-white tracking-wider">
                  {selectedItem.type === ItemType.timeBoosts
                    ? (selectedItem.name + ' ' + t('inventory.timeBoostsFullName')).toUpperCase()
                    : selectedItem.name.toUpperCase()}
                </h2>
              </div>
              <div className="w-[334px] text-[#dfd9c4] text-sm font-medium leading-[18px] mb-[28px]">
                {selectedItem.description}
              </div>
              {selectedItem.type === ItemType.lootBox && (
                <>
                  <div className="w-full flex justify-center mb-[28px]">
                    <div className="w-[80%]">
                      <QuantitySelector max={selectedItem.amount} value={quantity} onChange={setQuantity} />
                    </div>
                  </div>
                  <div className="flex justify-center items-center absolute bottom-[34px] left-0 w-full">
                    <PopupButton
                      disabled={quantity === 0 || isProcessing}
                      onClick={handleUse}
                      type={isProcessing ? 'gray' : quantity === 0 ? 'gray' : 'blue'}
                    >
                      <div className="text-center text-[#ffefcb] text-[18px] font-semibold  leading-[18px] py-0.5 px-6">
                        Use
                      </div>
                    </PopupButton>
                  </div>
                </>
              )}
              {(selectedItem.type === ItemType.keyShard || selectedItem.type === ItemType.heroShard) && (
                <div className="flex justify-center items-center absolute bottom-[34px] left-0 w-full">
                  <PopupButton onClick={handleClickUseLink} type={'blue'}>
                    <div className="text-center text-[#ffefcb] text-[18px] font-semibold leading-[18px] py-0.5 px-6">
                      Use
                    </div>
                  </PopupButton>
                </div>
              )}
            </div>
          )}
          {rewards.length && openBoxName ? (
            <OpenLootBox rewards={rewards} onClose={handleClose} openBoxName={openBoxName} />
          ) : null}
        </div>
      </div>
    </div>
  );
};
