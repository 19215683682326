import React, { useEffect, useState } from 'react';
import '../../App.css';
import { Router } from '../Router';
import { useDispatch, useSelector } from 'react-redux';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { AppDispatch, RootState, store } from '../../app/store';
import { useTelegram } from '../../hooks/useTelegram';
import { Preloader } from '../../layout/components/Preloader';
import { ConnectionError } from '../../layout/components/ConnectionError';
import ReactGA from 'react-ga4';
import { setUserId } from '../../app/features/userIdSlice';
//import * as amplitude from "@amplitude/analytics-browser";
//import { sessionReplayPlugin } from "@amplitude/plugin-session-replay-browser";
import { APP_ENV } from '../../config';
import * as Sentry from '@sentry/react';
import { FallbackComponent } from '../FallbackComponent';
import { ErrorComponent } from '../../components';
import { TwaAnalyticsProvider } from '@tonsolutions/telemetree-react';
import { preloadImages } from '../../utils/preloadImages';
import { openWebSocket } from '../../Websocket/websocketInit';

export const App = () => {
  const { hasError, error } = useSelector((state: RootState) => state.error);
  const dispatch = useDispatch<AppDispatch>();
  const [loading, setLoading] = React.useState(true);
  const { userId } = useTelegram();
  const webSocketStatus = useSelector((state: RootState) => state.webSocket.status);

  useEffect(() => {
    dispatch(openWebSocket());
  }, []);

  sessionStorage.setItem('hasInteracted', 'false');

  if (APP_ENV === 'production') {
    ReactGA.initialize('G-NX7X1LGVEZ');

    const config = [userId];
    window.devtodev.initialize('6382459a-1693-054c-8965-16c4c59acac4', config);
  }

  useEffect(() => {
    if (userId) {
      store.dispatch(setUserId(userId));

      setTimeout(() => {
        if (APP_ENV === 'production') {
          // amplitude.init("a8b624aadbb127ce1c4bea6f0bdcb1a8");
          // const sessionReplayTracking = sessionReplayPlugin({
          //   sampleRate: 0.2,
          // });
          // amplitude.add(sessionReplayTracking);
        }

        let imagePaths: string[] = [
          require('../../assets/images/bugImages/smthWentWrong.png'),
          require('../../assets/images/bugImages/retryConnection.png'),
        ];

        preloadImages(imagePaths);

        setLoading(false);
      }, 1500);
    }
  }, [userId]);

  useEffect(() => {
    const handleContextMenu = (e: MouseEvent) => {
      if ((e.target as HTMLElement).tagName === 'IMG') {
        e.preventDefault();
      }
    };

    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

  // const userAgent = navigator.userAgent.toLowerCase();
  // const desktopOnly =
  //   !/mobile|android|iphone|ipad|phone/.test(userAgent) &&
  //   APP_ENV === "production";

  // setTimeout(() => {
  //   dispatch(setError(new Error('Connection error')));
  // }, 15000);

  if (!hasError) {
    sessionStorage.removeItem('reloadAttempts');
  }

  return (
    <Sentry.ErrorBoundary
      fallback={({ error, resetError, componentStack, eventId }) => {
        return <ErrorComponent hasError={hasError} jsError={{ error, resetError, componentStack, eventId }} />;
      }}
    >
      <TonConnectUIProvider manifestUrl="https://dungeonsofkitsune.com/manifest.json">
        {/* <TwaAnalyticsProvider
          projectId='cd5964bb-a2bc-47fa-af74-76d28355cab1'
          apiKey='71481611-ea19-4722-aa97-874791cc62fc'
        {/* <TwaAnalyticsProvider
          projectId='cd5964bb-a2bc-47fa-af74-76d28355cab1'
          apiKey='71481611-ea19-4722-aa97-874791cc62fc'
          //appName='KITSU'
        > */}
        <div className="App">
          <Router />
          <Preloader loading={loading} />
          <ConnectionError isShow={webSocketStatus === "failed"}/>
          {/*<button className="absolute m-3 bg-[red] z-[100]"*/}
          {/*  onClick={() => dispatch(setError(new Error('Connection error')))}>Get error</button>*/}
        </div>
        {/* </TwaAnalyticsProvider> */}
      </TonConnectUIProvider>

      {/*{desktopOnly && (*/}
      {/*  <div className="absolute flex justify-center items-center w-full h-full bg-[#201a1a] z-[9999]">*/}
      {/*    <img*/}
      {/*      src={require(`../../assets/images/pc-only.jpg`)}*/}
      {/*      className="w-full max-w-[480px] h-auto"*/}
      {/*      alt=""*/}
      {/*    />*/}
      {/*    <div className={`absolute bottom-[70px] w-full text-center `}>*/}
      {/*      <div className="text-[#e1c78d] mb-[5px]">*/}
      {/*        {" "}*/}
      {/*        More info in official channels*/}
      {/*      </div>*/}
      {/*      <div className="flex w-full justify-center gap-2">*/}
      {/*        <a*/}
      {/*          href="https://x.com/kitsuneton"*/}
      {/*          className="relative flex justify-center items-center w-[45px] h-[45px] bg-[#312e27] border-solid border-[3px] border-[#5f5343] rounded-full "*/}
      {/*        >*/}
      {/*          <img*/}
      {/*            className="absolute w-[90%] h-[90%]"*/}
      {/*            src={require("../../assets/images/quests/x.png")}*/}
      {/*            alt="Link to X"*/}
      {/*          />*/}
      {/*        </a>*/}
      {/*        <a*/}
      {/*          href="https://t.me/kitsune_ton"*/}
      {/*          className="relative flex justify-center items-center w-[45px] h-[45px] bg-[#312e27] border-solid border-[3px] border-[#5f5343] rounded-full  "*/}
      {/*        >*/}
      {/*          <img*/}
      {/*            className="absolute w-[90%] h-[90%]"*/}
      {/*            src={require("../../assets/images/quests/tg.png")}*/}
      {/*            alt="Link to Telegram"*/}
      {/*          />*/}
      {/*        </a>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*)}*/}
    </Sentry.ErrorBoundary>
  );
};
