import React, { useEffect, useMemo, useState } from 'react';
import { Offer } from '../../pages/Shop/components';
import { RootState } from '../../app/store';
import { useSelector } from 'react-redux';
import { OfferResourceProps } from '../../pages/Shop/components/OfferResources';
import { defineCountString } from '../../utils/farmConfigParser';
import { getIsSelected } from '../../pages/Shop';
import { ConfigOffer, OfferType } from '../../mock/offers';
import { OfferProps } from '../../pages/Shop/components/Offer';
import { motion } from 'framer-motion';
import { usePurchase } from '../../hooks/usePurchase';
import { getUserOffers } from '../../endpoints/getProfileInfo';
import { useTelegram } from '../../hooks/useTelegram';
import { SuccessOverlay } from '../SuccessOverlay';
import { useSessionStorage } from '@uidotdev/usehooks';

const TavernOffer = ({ offer, setOpenOffer }: { offer: any; setOpenOffer: (arg: boolean) => void }) => {
  // const appConfig = useSelector((state: RootState) => state.appConfig.configs);
  const { selectedOffer, isPurchaseLoading, isSuccessPurchase, confirmPurchase, resetPurchase } = usePurchase();
  const [purchasedOfferIds, setPurchasedOfferIds] = useState<Set<number> | null>(null);
  const { userId } = useTelegram();

  useEffect(() => {
    const getUserOffersData = async () => {
      const userOffersData = await getUserOffers({
        clientId: userId,
      });

      if (Array.isArray(userOffersData)) {
        setPurchasedOfferIds(
          new Set(userOffersData.map((o: { offerId: number; offerType: number; quantity: number }) => o.offerId))
        );
      }
    };
    getUserOffersData();
  }, []);

  const [isTavernshownOnly, setIsTavernshownOnly] = useSessionStorage('isTavernshownOnly', false);

  const handleClose = () => {
    setOpenOffer(false);
    setIsTavernshownOnly(true);
  };

  return (
    <div className="fixed w-full h-full top-0 left-0 flex items-center justify-center z-[57]">
      <div className="absolute w-full h-full top-0 left-0 bg-black opacity-80"></div>
      <motion.div
        initial={{ transform: 'translateY(200%)', opacity: '0' }}
        animate={{ transform: 'translateY(0%)', opacity: '1' }}
        exit={{ transform: 'translateY(100%)' }}
        transition={{ delay: 0.3 }}
        className={`relative flex flex-col items-center gap-6 bottom-4`}
      >
        <h2 className="text-white text-[24px] text-stroke-small">
          Need more stone? <br />
          There's a solution!
        </h2>
        <button
          className="absolute z-10 w-12 h-12 p-2 -top-1 -right-4  flex items-center justify-center"
          onClick={handleClose}
        >
          <img src={require('../../assets/images/shop-modal-close.png')} className="w-8 h-8" alt="" />
        </button>
        <motion.div key={offer.id}>
          <Offer
            onClick={() => confirmPurchase({ offerId: offer.id })}
            offerType={offer.offerType}
            id={offer.id}
            resourceList={offer.resourceList}
            rankPoints={`${defineCountString(Math.floor(Number(offer.price) / 10))}`}
            layout={offer.layout}
            type={offer.type}
            label={offer.label}
            kitsu={offer.kitsu}
            price={offer.price}
            deadline={offer.deadline}
            isClaimed={offer.isClaimed}
          />
        </motion.div>

        <div className="absolute left-[15px] -bottom-[90px] flex items-baseline gap-2 z-[56]">
          <img src={require('../../assets/images/offers/arrow-stone-offer.png')} alt="" />
          <p className="text-white text-[16px] text-stroke-small">Now 100x profit!</p>
        </div>
      </motion.div>

      <SuccessOverlay isSuccess={isSuccessPurchase} />
    </div>
  );
};

export default TavernOffer;
