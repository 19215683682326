import React, { useState, useEffect, useCallback, memo } from 'react';
import { CheckInIcon } from '../../../../../components';
import { PurchasePopup } from '../PurchasePopup';
import { useSessionStorage } from '@uidotdev/usehooks';
import { DepositIcon } from '../../../../../components/DepositIcon';
import { CheckIn } from '../../../../CheckIn';
import { DepositPopup } from '../DepositPopup';
import { useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import { ConnectWalletIcon } from '../../../../../components/ConnectWalletIcon';
import { resourcesMap } from '../../../../../utils/mapping';
import { Resources } from '../../../../../enums/resources';
import { PopupButton } from '../../../../../components/PopupButton';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../app/store';
import { TutorialFarmStage } from '../../../../../interfaces/tutorial';
import SbtPopup from '../SbtPopup';
import { ConnectWallet } from '../../../../Setting/components';
import ConnectWalletPopup from '../../../../../components/ConnectWalletPopup';

interface Props {
  firstConnectWallet: boolean;
  shouldHideElement: boolean;
}

export const IconsAndModals: React.FC<Props> = memo(({ firstConnectWallet, shouldHideElement }) => {
  const { t } = useTranslation();
  const [openCheckIn, setOpenCheckIn] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [openedDepositModal, setOpenedDepositModal] = useSessionStorage('openedDepositModal', false);
  // const [openedModal, setOpenedModal] = useSessionStorage('openModelFarm', false);
  // const [openConnectWallet, setOpenConnectWallet] = useState(false);
  const [openConnectWalletPopup, setOpenConnectWalletPopup] = useSessionStorage('openConnectWalletPopup', false);

  const [sbtModal, setSbtModal] = useSessionStorage<boolean>('sbtModal', false);

  const farmTutorialSave = useSelector((state: RootState) => state.farmTutorial.tutorialFarmSave.save);

  const address = useTonAddress();
  const [tonConnectUI] = useTonConnectUI();

  const handleConnect = useCallback(() => {
    tonConnectUI.openModal();
    setOpenConnectWalletPopup(false);
  }, []);
  // console.log('openConnectWallet', openConnectWallet);

  const handleOnClickToolTip = useCallback(async () => {
    setOpenConnectWalletPopup(!openConnectWalletPopup);
    setTimeout(() => {
      setShowTooltip(false);
    }, 500);
  }, [openConnectWalletPopup]);

  const handleOpenDepositModal = useCallback(() => {
    setOpenedDepositModal(!openedDepositModal);
  }, []);

  const handleOpenCheckIn = useCallback(() => {
    setOpenCheckIn(!openCheckIn);
  }, []);

  const toggleTooltip = () => {
    setTimeout(() => {
      setShowTooltip(true);
      setTimeout(() => {
        setShowTooltip(false);
      }, 7000);
    }, 5000);
  };

  const handleToggleSbtModal = () => {
    setSbtModal(!sbtModal);
  };

  useEffect(() => {
    toggleTooltip();
  }, []);

  const tutorialActive =
    !farmTutorialSave?.stage ||
    (farmTutorialSave?.stage !== TutorialFarmStage.finishFirstBuilding &&
      farmTutorialSave?.stage !== TutorialFarmStage.finishSecondBuilding);
  return (
    <>
      {sbtModal ? <SbtPopup handleToggleSbtModal={handleToggleSbtModal} /> : null}
      {!shouldHideElement && !openedDepositModal && !tutorialActive && (
        <div className="absolute inset-0 bg-black bg-opacity-80 z-[9900]"></div>
      )}
      {firstConnectWallet && !tutorialActive && (
        <div className="absolute z-[55] top-[90px] right-2.5">
          <ConnectWalletIcon
            link={'/quests'}
            onClick={handleOnClickToolTip}
            onClickToolTip={handleOnClickToolTip}
            isToolTipOpen={showTooltip}
          />
        </div>
      )}
      <div
        className={`absolute z-[55] ${firstConnectWallet ? 'top-[145px]' : 'top-[80px]'} ${tutorialActive ? 'opacity-0 pointer-events-none' : ''} right-2`}
      >
        <DepositIcon onClick={handleOpenDepositModal} />
      </div>
      <div
        className={`absolute z-[55] ${firstConnectWallet ? 'top-[220px]' : 'top-[150px]'} ${tutorialActive ? 'opacity-0 pointer-events-none' : ''} right-2`}
      >
        <CheckInIcon onClick={handleOpenCheckIn} />
      </div>

      {!shouldHideElement && !tutorialActive ? (
        <div className="absolute min-h-full max-h-full h-full flex min-w-full overflow-hidden z-[9909] ">
          <PurchasePopup />
        </div>
      ) : null}
      {openCheckIn ? <CheckIn close={() => setOpenCheckIn(false)} /> : null}
      {openedDepositModal ? (
        <div className={`absolute z-[9910] w-full h-full bg-[rgba(0,0,0,0.8)]`}>
          <DepositPopup onClick={handleOpenDepositModal} address={address} />
        </div>
      ) : null}
      {/* {openConnectWallet ? (
        <div className="absolute inset-0 bg-black opacity-60 z-[999]" onClick={() => setOpenConnectWallet(false)}></div>
      ) : null} */}

      {/* <div
        className={`absolute transform transition-transform duration-200 z-[999] left-0 right-0 p-5 h-[282px] 
                -bottom-[282px] bg-no-repeat bg-[length:100%_100%] bg-[url('./assets/images/shop-buy-modal-background.png')] 
                ${openConnectWallet ? '-translate-y-full' : 'translate-y-0'}`}
      >
        <button
          className="absolute z-10 w-12 h-12 p-2 top-1.5 right-1.5  flex items-center justify-center"
          onClick={() => setOpenConnectWallet(false)}
        >
          <img src={require('../../../../../assets/images/shop-modal-close.png')} className="w-8 h-8" alt="" />
        </button>
        <div className="text-[30px] leading-[1.2] mb-2 text-[#19191B] text-center">{t('profile.connect')}</div>
        {firstConnectWallet && (
          <div className="flex flex-col gap-2 justify-center items-center">
            <div className="flex justify-center items-center gap-2">
              <div className="w-full text-[#18191a] text-lg font-black  leading-[18px]">
                Connect Wallet to get reward
              </div>
              <div className="bg-[#8e855e] rounded shadow-inner border border-[#2a2827]/70 flex gap-2 p-1">
                <div className="w-[26px] h-[26px]">
                  <img className="w-full h-full" src={resourcesMap[Resources.kitsu].image} />
                </div>

                <div className="text-center text-[#ffc923] text-[25px] font-black uppercase leading-[25px] tracking-wide">
                  200
                </div>
              </div>
            </div>
          </div>
        )}

        {!address ? (
          <div className={` flex justify-center ${!address ? 'mt-8' : 'mt-2'}`}>
            <div className="mt-5">
              <PopupButton type="blue" width="200px" onClick={handleConnect}>
                {t('profile.connect')}
              </PopupButton>
            </div>
          </div>
        ) : null}
      </div> */}
      {openConnectWalletPopup ? <ConnectWalletPopup firstConnectWallet={firstConnectWallet} /> : null}
      {/* <ConnectWallet /> */}
    </>
  );
});
