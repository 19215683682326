import { useDispatch, useSelector } from 'react-redux';
import { setSelectedRoom } from '../../../../../app/features/selectedRoom';
import { PopupWindow } from '../../../../../components/PopupWindow';
import { PopupButton } from '../../../../../components/PopupButton';
import { useTranslation } from 'react-i18next';
import { UpgradeArrow } from '../../../../Room';
import {
  GoldIco,
  GrayIco,
  KitsuIco,
  StoneIco,
  TimerIco,
} from '../../../../../layout/components/HeaderFarm/components/ResourceCard';
import { AppDispatch, RootState, store } from '../../../../../app/store';
import { FarmRoom } from '../../../../../interfaces/farm';
import { fetchIslands, upgradeIslandRoom } from '../../../../../app/features/islandsSlice';
import { setResource } from '../../../../../app/features/resourcesSlice';
import { Resources } from '../../../../../enums/resources';
import { PopupUpgradeTable, UpgradeDataRow } from '../../../../../components/PopupUpgradeTable';
import React, { useEffect, useState } from 'react';

import { getBalance, upgradeBuilding } from '../../../../../endpoints/farmMock';
import { useTelegram } from '../../../../../hooks/useTelegram';
import { AdditionalCondition, AdditionalConditionType, defineCountString } from '../../../../../utils/farmConfigParser';
import { OfferResourceProps } from '../../../../../pages/Shop/components/OfferResources';
import { Offer, OfferProps } from '../../../../../pages/Shop/components/Offer';
import { ConfigOffer, OfferType } from '../../../../../mock/offers';
import { usePurchase } from '../../../../../hooks/usePurchase';
import { HandleBackButton } from '../../../../../layout/components/HeaderCave/components';
import { PurchaseModal } from '../../../../../components/PurchaseModal';
import { SuccessOverlay } from '../../../../../components/SuccessOverlay';
import { useLocalStorage, useSessionStorage } from '@uidotdev/usehooks';
import { AnimatePresence, motion } from 'framer-motion';
import { useRoom } from '../../../Room/useRoom';
import { RoomStatus } from '../../../../../enums/buildingStatus';
import { getAdditionalStats } from '../../../../../endpoints/refEndpoints';
import { LvlGateButton } from '../LvlGateButton';
import { useSoundService } from '../../../../../utils/soundService';
import { getIsSelected } from '../../../../../pages/Shop';
import { getUserOffers } from '../../../../../endpoints/getProfileInfo';
import { DungeonTypeConfig } from '../../../../../endpoints/mock';
import { msToTime } from '../../../../../utils/timer';

export const BuildingPopup = () => {
  const dispatch = useDispatch();
  const appDispatch = useDispatch<AppDispatch>();
  const { playSound } = useSoundService();
  const { t } = useTranslation();
  const { buildingConfigs } = useSelector((state: RootState) => state.config);
  const { userId } = useTelegram();
  const [loading, setLoading] = useState(false);

  const [requiredConditions, setRequiredConditions] = useState<
    {
      condition: string;
      met: boolean;
      buildingId?: number;
      lvl?: number;
    }[]
  >([]);
  const [requiredAdditionalConditions, setRequiredAdditionalConditions] = useState<AdditionalCondition[]>();

  const currentRoom = useSelector((state: RootState) => state.selectedRoom);

  const selectedIsland = useSelector((state: RootState) => state.selectedIsland.selectedIsland);

  //console.log("ISLAND", selectedIsland);

  const resources = useSelector((state: RootState) => state.resources.resources);

  const selectedRoom = buildingConfigs!.filter((v) => v.id === currentRoom?.buildingId)?.[0];

  let maxLvl = Math.max(...(selectedRoom?.lvlInfo.map((info) => info.lvl) || [0]));

  if (selectedRoom?.id === 7) {
    maxLvl = 5;
  }

  const isMaxLevel = currentRoom.lvl === maxLvl;

  const closePopup = () => {
    dispatch(setSelectedRoom({ id: -1 }));
  };

  const upgradeAvailableInfo = () => {
    const conditions = selectedRoom?.lvlInfo.find((v) => v.lvl === currentRoom.lvl!)?.condition;

    let requiredConditions: string[] = [];

    if (!conditions) {
      return requiredConditions;
    }

    const islandsFromRedux = store.getState().islands;
    const allBuildings: FarmRoom[] = islandsFromRedux.islands
      .map((island) => island.buildings.filter((v) => v?.buildingId))
      .flat(1);

    conditions.forEach((condition) => {
      const allBuildingsLvlForCondition = allBuildings
        .filter((v) => v.buildingId === condition.buildingId)
        .map((building) => (building.lvl ? building.lvl : 0))
        .flat(1);
      const maxLvl = Math.max(...allBuildingsLvlForCondition);

      if (!maxLvl || condition.neededLvl > maxLvl) {
        requiredConditions.push(
          '\n' +
            t(buildingConfigs!.find((v) => v.id === condition.buildingId)?.title || 'Unknown') +
            ' ' +
            t('farm.upgrade.requirements', { lvl: condition.neededLvl })
        );
      }
    });

    return requiredConditions;
  };

  const [lvlGateUserStats, setLvlGateUserStats] = useSessionStorage<Record<number, { lvl: number; status: boolean }>>(
    'lvlGateConditions',
    {}
  );

  const addOrUpdateBuildingLvlGateStats = (buildingId: number, newLvl: number, status?: boolean) => {
    setLvlGateUserStats((prev) => {
      const existingStat = prev[buildingId];

      if (!existingStat) {
        return {
          ...prev,
          [buildingId]: { lvl: newLvl, status: false },
        };
      }

      if (status) {
        return {
          ...prev,
          [buildingId]: { lvl: newLvl, status: true },
        };
      }

      if (existingStat.lvl === newLvl) {
        return prev;
      }

      if (newLvl > existingStat.lvl) {
        return {
          ...prev,
          [buildingId]: { lvl: newLvl, status: false },
        };
      }

      return prev;
    });
  };

  useEffect(() => {
    const upgradeConditions = () => {
      if (!selectedRoom) {
        return [];
      }

      const levelInfo = selectedRoom.lvlInfo.find((v) => v.lvl === currentRoom.lvl!);

      if (!levelInfo) {
        return [];
      }

      const conditions = levelInfo.condition;

      const additionalConditions = levelInfo.additionalCondition;
      if (additionalConditions) {
        setRequiredAdditionalConditions(additionalConditions);
        addOrUpdateBuildingLvlGateStats(currentRoom.buildingId!, currentRoom.lvl!);
      }

      let requiredConditions: {
        condition: string;
        met: boolean;
        buildingId?: number;
        lvl?: number;
        status?: number;
      }[] = [];

      const islandsFromRedux = store.getState().islands;
      const allBuildings: FarmRoom[] = islandsFromRedux.islands
        .map((island) => island.buildings.filter((v) => v?.buildingId))
        .flat(1);

      if (conditions) {
        conditions.forEach((condition) => {
          const allBuildingsLvlForCondition = allBuildings
            .filter((v) => v.buildingId === condition.buildingId)
            .map((building) => building.lvl || 0);

          const maxLvl = Math.max(...allBuildingsLvlForCondition);

          const buildingTitle = buildingConfigs!.find((v) => v.id === condition.buildingId)?.title || 'Unknown';
          const buildingStatus = allBuildings
            .filter((v) => v.buildingId === condition.buildingId)
            .map((building) => building.status || undefined)[0];

          const conditionMet = maxLvl >= condition.neededLvl && buildingStatus !== RoomStatus.building;

          requiredConditions.push({
            condition: ` ${t('farm.upgrade.requirements', {
              lvl: condition.neededLvl,
            })} ${t(buildingTitle)}`,
            met: conditionMet,
            buildingId: condition.buildingId,
            lvl: maxLvl,
            status: buildingStatus,
          });
        });
      }

      return requiredConditions;
    };

    const newConditions = upgradeConditions();

    setRequiredConditions(newConditions);
  }, [selectedRoom, currentRoom, resources, t, buildingConfigs]);

  const allConditionsMetNormal = requiredConditions.every((row) => row.met);

  const levelInfo = selectedRoom?.lvlInfo.find((v) => v.lvl === currentRoom.lvl!);

  const nextLevelInfo = selectedRoom?.lvlInfo.find((v) => v.lvl === currentRoom.lvl! + 1);

  const maxTimeMs = {
    nowMaxTime: levelInfo?.maxTimeMs,
    newMaxTime: nextLevelInfo?.maxTimeMs,
  };

  const createCost = levelInfo?.createCost || 0;
  const goldCost = levelInfo?.kitsune || 0;

  const currentKitsu = resources.find((v) => v.resourceType === Resources.kitsu)?.value || 0;

  const currentStone = resources.find((v) => v.resourceType === Resources.stone)?.value || 0;

  const canUpgradeGold = allConditionsMetNormal && currentKitsu >= goldCost;

  const canUpgradeNormal = allConditionsMetNormal && currentStone >= createCost;

  const upgradeNormalCondition = {
    met: canUpgradeNormal,
    currentAmount: currentStone,
    costAmount: createCost,
  };

  const onClickUpgrade = async (isGoldUpgrade = false) => {
    playSound('upgrade');

    const levelInfo = selectedRoom?.lvlInfo.find((v) => v.lvl === currentRoom.lvl!);

    const currentKitsu = resources.find((v) => v.resourceType === Resources.kitsu)?.value || 0;

    const currentStone = resources.find((v) => v.resourceType === Resources.stone)?.value || 0;

    let requiredConditions = upgradeAvailableInfo();

    if (requiredConditions.length > 0) {
      window.alert(requiredConditions.join(' '));
      setLoading(false);
      return;
    }

    if (isGoldUpgrade) {
      const result = await upgradeBuilding({
        islandId: selectedIsland?.id!,
        buildingId: currentRoom.id,
        clientId: userId,
        instabuild: true,
      });

      if (result) getBalance({ clientId: userId });
    } else {
      const result = await upgradeBuilding({
        islandId: selectedIsland?.id!,
        buildingId: currentRoom.id,
        clientId: userId,
        instabuild: false,
      });

      if (result) getBalance({ clientId: userId });
    }
    setLoading(false);
    closePopup();
  };

  const upgradeData = () => {
    if (selectedRoom) {
      let upgradeData: UpgradeDataRow[] = [];
      const currentLvl = selectedRoom.lvlInfo.find((v) => v.lvl === currentRoom.lvl);

      currentLvl?.upgradeData?.forEach((v, i) => {
        let row = {
          title: 'ERROR IN containers/Farm/IslandWindow/components/BuildingPopup 288',
          nowValue: 0,
          newValue: 0,
        };
        switch (i) {
          case 0: {
            row = {
              title: v.title,
              nowValue: currentLvl.resourceAmount! * 720,
              newValue: v.value * 720,
            };
            break;
          }
          case 1: {
            row = {
              title: v.title,
              nowValue: currentLvl.maxTimeMs,
              newValue: v.value,
            };
            break;
          }
        }
        upgradeData.push(row);
      });

      return upgradeData;
    } else {
      return [];
    }
  };

  const WindowHeader = () => {
    return (
      <div className="flex  w-full items-center justify-start gap-4 mb-2">
        <div className="flex w-[140px] h-[140px] -mt-10">
          {selectedRoom && (
            <img src={require(`../../../../../assets/images/buildings/build${selectedRoom?.id}.png`)} alt="Building" />
          )}
        </div>
        <div className="flex flex-col">
          <p className="text-white text-[20px] text-stroke-small">{t(selectedRoom?.title).toUpperCase()}</p>
          <div className="flex flex-row items-center justify-center gap-1">
            <p className="text-white text-[17px] text-stroke-small">Lvl. {currentRoom?.lvl}</p>
            {!isMaxLevel && <UpgradeArrow direction="right" />}
            <p className="text-[#f6a000] text-base font-black leading-none">
              {isMaxLevel ? `MAX` : ` Lvl. ${currentRoom!.lvl! + 1}`}
            </p>
          </div>
        </div>
      </div>
    );
  };

  const preventTimeBug = 15000;

  const { selectedOffer, isPurchaseLoading, isSuccessPurchase, confirmPurchase, resetPurchase } = usePurchase();

  const appConfig = useSelector((state: RootState) => state.appConfig.configs);
  const {
    offers: { variables: allOffers },
  } = appConfig;

  //How much one stone costs in KITSU
  const kitsuToStoneCoeff = appConfig.variables.variables[0].kitsuToStone.value;

  const kitsuneStoreOffers = allOffers.filter((offer: ConfigOffer) => offer.offerType.value === OfferType.kitsune);

  const stoneStoreOffers = allOffers.filter(
    (offer: ConfigOffer) => offer.offerType.value === OfferType.stoneOffer && offer.offerId.value === 9
  );

  const requiredAmount = goldCost - currentKitsu;

  const selectedAmount = Math.min(
    ...kitsuneStoreOffers
      .flatMap((offer: ConfigOffer) => offer.items.value.map((item) => item.amount.value))
      .filter((amount: number) => amount >= requiredAmount)
  );
  // console.log('selectedAmount', selectedAmount);
  const selectedKitsuneOffer: ConfigOffer = kitsuneStoreOffers.find((offer: ConfigOffer) =>
    offer.items.value.some((item) => item.amount.value === selectedAmount)
  );
  const sortedItems = [...selectedKitsuneOffer.items.value].sort((a, b) => {
    const aSelected = getIsSelected(a);
    const bSelected = getIsSelected(b);

    if (aSelected === bSelected) return 0;
    return aSelected ? -1 : 1;
  });

  const resourceList: OfferResourceProps[] = sortedItems.map((item, index): OfferResourceProps => {
    const isEnabled = getIsSelected(item);

    return {
      resourceType: item.rewardType.value,
      resourceId: item.rewardId.value,
      isEnabled: isEnabled,
      layout: 'vertical',
      amount: defineCountString(item.amount.value),
    };
  });

  const mappedKitsuneStoreOffer: OfferProps = {
    id: selectedKitsuneOffer.offerId.value,
    resourceList: resourceList,
    offerType: selectedKitsuneOffer.offerType.value,
    type: 'gold',
    label: 'full_top',
    layout: 'full',
    price: selectedKitsuneOffer.stars.value.toString(),
    deadline: 'January 31, 2025',
  };

  const mappedStoneStoreOffer: OfferProps[] = stoneStoreOffers.map((offer: ConfigOffer): OfferProps => {
    const sortedItems = [...offer.items.value].sort((a, b) => {
      const aSelected = getIsSelected(a);
      const bSelected = getIsSelected(b);

      if (aSelected === bSelected) return 0;
      return aSelected ? -1 : 1;
    });

    const resourceList: OfferResourceProps[] = sortedItems.map((item, index): OfferResourceProps => {
      const isEnabled = getIsSelected(item);

      return {
        resourceType: item.rewardType.value,
        resourceId: item.rewardId.value,
        isEnabled: isEnabled,
        layout: 'vertical',
        amount: defineCountString(item.amount.value),
      };
    });

    return {
      id: offer.offerId.value,
      resourceList: resourceList,
      offerType: offer.offerType.value,
      type: 'gold',
      label: 'full_top',
      layout: 'full',
      price: defineCountString(offer.stars.value),
      deadline: 'January 31, 2025',
    };
  });
  // const selectedKitsuneOffer1 = mappedKitsuneStoreOffer.find((offer:) =>
  //   offer.items.value.some(item => item.amount.value === selectedKitsuneOffer.)
  // );

  // mappedKitsuneStoreOffer[]
  const stoneOffer = mappedStoneStoreOffer[0];
  const [showKitsuneOffer, setKitsuneShowOffer] = useState(false);
  const [showStoneOffer, setStoneShowOffer] = useState(false);

  const handleNoKitsunePromoteBuyPack = () => {
    setKitsuneShowOffer(true);
    setLoading(false);
  };

  const handleNoStonePromoteBuyPack = () => {
    setStoneShowOffer(true);
  };

  const [isBuilderBusy, setIsBuilderBusy] = useSessionStorage('isBuilderBusy', false);
  const [isVisible, setIsVisible] = useState(false);
  const variants = {
    hidden: { opacity: 0, y: 50 }, // Початковий стан: прозорість 0, зміщення вниз
    visible: { opacity: 1, y: 0 }, // Кінцевий стан: прозорість 1, без зміщення
    exit: { opacity: 0, y: 0 }, // Вихідний стан: прозорість 0, зміщення вниз
  };

  // Building lvl gates
  const [lvlGateStats, setLvlGateStats] = useState<{
    referrals: number;
    premiumReferrals: number;
    donated: number;
    rankLvl: number;
  }>();

  const fetchStats = async () => {
    try {
      const data = await getAdditionalStats({ clientId: userId });
      setLvlGateStats(data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchStats();
  }, [userId]);

  const handleUpgrade = () => {
    setLoading(true);

    if (!allConditionsMetNormal) return;

    const canUpgradeWithCurrentStone = currentKitsu >= goldCost && currentStone > createCost;

    const canUpgradeWithKitsuConversion =
      currentKitsu >= goldCost + createCost * kitsuToStoneCoeff && currentStone < createCost;

    if (canUpgradeWithCurrentStone || canUpgradeWithKitsuConversion) {
      onClickUpgrade(true);
    } else {
      handleNoKitsunePromoteBuyPack();
    }
  };

  const [purchasedOfferIds, setPurchasedOfferIds] = useState<Set<number> | null>(null);

  useEffect(() => {
    const getUserOffersData = async () => {
      const userOffersData = await getUserOffers({
        clientId: userId,
      });

      if (Array.isArray(userOffersData)) {
        setPurchasedOfferIds(
          new Set(userOffersData.map((o: { offerId: number; offerType: number; quantity: number }) => o.offerId))
        );
      }
    };
    getUserOffersData();
  }, [isSuccessPurchase]);

  const mintBlockOffers = allOffers.filter(
    (offer: ConfigOffer) => offer.offerType.value === OfferType.offerToUpdateBuilding
  );

  const currentMintBlockOffer: ConfigOffer = mintBlockOffers.find(
    (offer: ConfigOffer) =>
      offer.offerId.value ===
      requiredAdditionalConditions?.find((condition) => condition.conditionType === AdditionalConditionType.DonateStars)
        ?.value
  );

  const dungeonConfig: DungeonTypeConfig[] = React.useMemo(() => {
    if (!appConfig) return [];
    return appConfig.dungeonCards.variables;
  }, [appConfig]);

  // Інфа про блок данж стейджів
  const unlockDungeonStageData = () => {
    // шукаємо який стейдж ми тіки шо відкрили
    const currentDungeonUnlockInfo = dungeonConfig.find(
      (dung) =>
        dung.conditions?.value.find(
          (cond) => cond.buildingId.value === currentRoom.buildingId && cond.level.value === currentRoom.lvl!
        )?.buildingId.value === currentRoom.buildingId
    );
    // шукаємо який стейдж ми відкриємо некст
    const nextDungeonUnlockInfo = dungeonConfig.find(
      (dung) =>
        dung.conditions?.value.find(
          (cond) => cond.buildingId.value === currentRoom.buildingId && cond.level.value === currentRoom.lvl! + 1
        )?.buildingId.value === currentRoom.buildingId
    );

    // шукаємо білдінг у якому ми тіки шо відкрили стейдж
    const currentDungeonBuildingUnlockInfo = buildingConfigs?.find((building) =>
      building.dungeonIds?.find((dung) => dung.dungeonId === currentDungeonUnlockInfo?.dungeonId.value)
    );
    // шукаємо білдінг у якому ми віжкоиємо некст стейдж
    const nextDungeonBuildingUnlockInfo = buildingConfigs?.find((building) =>
      building.dungeonIds?.find((dung) => dung.dungeonId === nextDungeonUnlockInfo?.dungeonId.value)
    );

    // якщо ці білдінги однакові, то повертаємо тайтл цього білдінга і поточний на таступний стейдж
    if (currentDungeonBuildingUnlockInfo?.id === nextDungeonBuildingUnlockInfo?.id) {
      return {
        title: currentDungeonBuildingUnlockInfo?.title,
        currentStage:
          currentDungeonBuildingUnlockInfo?.dungeonIds?.findIndex(
            (item) => item.dungeonId === currentDungeonUnlockInfo?.dungeonId.value!
          )! + 1,
        nextStage:
          nextDungeonBuildingUnlockInfo?.dungeonIds?.findIndex(
            (item) => item.dungeonId === nextDungeonUnlockInfo?.dungeonId.value!
          )! + 1,
      };
    }

    // якщо білдінги різні, то повертаємо у якому білдінгу мі відкриємо некст стейдж
    return {
      title: nextDungeonBuildingUnlockInfo?.title,
      nextStage:
        nextDungeonBuildingUnlockInfo?.dungeonIds?.findIndex(
          (item) => item.dungeonId === nextDungeonUnlockInfo?.dungeonId.value!
        )! + 1,
    };
  };

  return (
    <div className="absolute w-full h-full flex justify-center items-center min-w-full max-w-full min-h-full max-h-full z-[999]">
      <div onClick={closePopup} className="absolute z-40 h-full w-full bg-[rgba(0,0,0,0.5)]" />
      <PopupWindow onClickClose={closePopup} headerElement={<WindowHeader />}>
        <div className="w-full">
          {unlockDungeonStageData().title && (
            <div className="w-full flex flex-col">
              <div className="flex flex-row items-center justify-between pt-4 px-4">
                <div className="flex items-center gap-2">
                  {/* Boss label image */}
                  <img src={require('../../../../../assets/images/dungeon-boss-label.png')} className="w-10" alt="" />
                  {/* Title */}
                  <p className="text-[18px] text-white">{t(unlockDungeonStageData().title!)}</p>
                </div>

                {/* Values with Arrow & without Arrow */}
                {unlockDungeonStageData().currentStage ? (
                  <div className="flex flex-row items-center justify-end gap-2">
                    <p className="text-[13px] text-white">{unlockDungeonStageData().currentStage}</p>
                    <UpgradeArrow direction="right" />
                    <p className="text-[13px] text-[#F6A000]">{unlockDungeonStageData().nextStage}</p>
                  </div>
                ) : (
                  <div className="flex flex-row items-center justify-end gap-2">
                    <p className="text-[13px] text-[#F6A000]">{unlockDungeonStageData().nextStage}</p>
                  </div>
                )}
              </div>
              {/* Separator */}
              <div className="w-full px-4 mt-4 mb-4">
                <div className="w-full h-[3px] bg-[#181615] mb-1"></div>
                <div className="w-full h-[3px] bg-[#3b332e]"></div>
              </div>
            </div>
          )}
        </div>
        {isMaxLevel ? (
          <div className="w-full p-10 text-center text-[#f6a000] text-[28px] font-normal leading-7">
            You have reached the
            <br />
            highest level
          </div>
        ) : requiredAdditionalConditions &&
          selectedRoom &&
          !lvlGateUserStats[selectedRoom.id]?.status &&
          lvlGateUserStats[selectedRoom.id]?.status !== undefined ? (
          <div className="w-full">
            <div className="w-full">
              {upgradeData().map(
                (row, i) =>
                  row.nowValue != row.newValue && (
                    <div key={i} className={`w-full flex flex-col ${i % 2 ? 'bg-[#362d28]' : 'bg-transparent'} `}>
                      <div className="flex flex-row items-center justify-between py-4 px-6">
                        {/* Title */}
                        <p className="text-[18px] text-white">{t(row.title)}</p>

                        {/* Values with Arrow */}
                        <div className="flex flex-row items-center justify-end gap-2">
                          <p className="text-[13px] text-white">{i === 1 ? msToTime(row.nowValue) : row.nowValue}</p>
                          <UpgradeArrow direction="right" />
                          <p className="text-[13px] text-[#F6A000]">
                            {i === 1 ? msToTime(row.newValue) : row.newValue}
                          </p>
                        </div>
                      </div>

                      {/* Separator */}
                      {i < upgradeData.length - 1 && <div className="h-px bg-[#584d3c] mx-6"></div>}
                    </div>
                  )
              )}
            </div>

            {/* Divider Between Sections */}
            <div className="w-full px-4 mt-4 mb-4">
              <div className="w-full h-[3px] bg-[#181615] mb-1"></div>
              <div className="w-full h-[3px] bg-[#3b332e]"></div>
            </div>
            {lvlGateStats &&
              (requiredAdditionalConditions.some((condition) => {
                switch (condition.conditionType) {
                  case AdditionalConditionType.Friends:
                    return lvlGateStats.referrals >= condition.value;
                  case AdditionalConditionType.PremiumFriends:
                    return lvlGateStats.premiumReferrals >= condition.value;
                  case AdditionalConditionType.RankLvl:
                    return lvlGateStats.rankLvl >= condition.value;
                  default:
                    return false;
                }
              }) ||
              (currentMintBlockOffer && purchasedOfferIds?.has(currentMintBlockOffer.offerId.value)) ? (
                <div className="flex items-center justify-center px-2">
                  <PopupButton
                    className="mx-auto"
                    width="150px"
                    type="green"
                    onClick={() => {
                      addOrUpdateBuildingLvlGateStats(selectedRoom.id, currentRoom.lvl!, true);
                    }}
                  >
                    <div className="flex items-center justify-center h-[30px]">
                      <button className="text-[18px] tracking-wide py-1 text-stroke-small">Unlock</button>
                    </div>
                  </PopupButton>
                </div>
              ) : (
                <>
                  <p className="text-[18px] text-center text-white mb-2">Unlock by:</p>
                  <div className="flex items-center gap-[7px] px-2">
                    {requiredAdditionalConditions.map((condition, index) => (
                      <React.Fragment key={index}>
                        {(index + 1) % 2 === 0 && <p className="text-sm text-center text-[#E1C78C]">or</p>}
                        <LvlGateButton
                          stats={lvlGateStats}
                          condition={condition}
                          closePopup={closePopup}
                          starsValue={currentMintBlockOffer ? currentMintBlockOffer.stars.value : 0}
                          onClick={() => {
                            confirmPurchase({
                              offerId: currentMintBlockOffer.offerId.value,
                            });
                          }}
                        />
                      </React.Fragment>
                    ))}
                  </div>
                </>
              ))}
          </div>
        ) : (
          <div className="w-full">
            <PopupUpgradeTable
              upgradeData={upgradeData()}
              requiredConditions={requiredConditions}
              upgradeNormalCondition={upgradeNormalCondition}
              maxTimeMs={maxTimeMs}
            />
          </div>
        )}

        {isMaxLevel ? (
          <div></div>
        ) : (
          <>
            {requiredAdditionalConditions &&
              selectedRoom &&
              !lvlGateUserStats[selectedRoom.id]?.status &&
              lvlGateUserStats[selectedRoom.id]?.status !== undefined && (
                <div className="w-full flex items-center justify-center h-[27%] bottom-0 absolute">
                  <div className="z-[51] flex items-center justify-center gap">
                    <div className="w-[29px] h-[29px]">
                      <img
                        className="w-full h-full"
                        src={require('../../../../../assets/images/heroes/icons/closed.png')}
                      />
                    </div>
                    <p className="text-white text-xl text-stroke-small">Locked</p>
                  </div>
                  <div className="absolute w-full h-full bg-black opacity-50 z-50"></div>
                </div>
              )}
            <div className="flex justify-between items-center py-8 px-5 gap-4 w-full">
              {/* Золота Кнопка Оновлення */}
              <PopupButton
                type={allConditionsMetNormal ? 'gold' : 'gray'}
                disabled={!allConditionsMetNormal || loading}
                onClick={handleUpgrade}
                className="w-full"
              >
                <div className="w-full items-center p-1 h-full gap-1">
                  <div className="flex flex-row justify-center items-center gap-[2px]">
                    <div className="w-5 h-5 flex justify-center items-center">
                      <KitsuIco />
                    </div>
                    <p className="text-white text-base font-bold leading-[14px]">
                      {currentStone < createCost
                        ? goldCost + Math.ceil((createCost - currentStone) * kitsuToStoneCoeff)
                        : goldCost}
                    </p>
                  </div>
                </div>
              </PopupButton>

              <PopupButton
                type={allConditionsMetNormal ? 'green' : 'dark-gray'}
                disabled={!allConditionsMetNormal}
                onClick={
                  isBuilderBusy
                    ? () => {
                        setIsVisible(true);
                        setTimeout(() => {
                          setIsVisible(false);
                        }, 1000);
                      }
                    : allConditionsMetNormal
                      ? currentStone >= createCost
                        ? () => onClickUpgrade(false)
                        : handleNoStonePromoteBuyPack
                      : undefined
                }
                className="w-full"
              >
                <div className="flex  flex-col w-full items-center p-1 h-full gap-1">
                  <div className="flex flex-row justify-center items-center gap-[2px]">
                    <div className="w-[20px] h-[20px] flex justify-center items-center">
                      <img
                        className="w-full h-full object-contain"
                        src={require('../../../../../assets/images/shop/offer/clock.png')}
                      />
                    </div>

                    <p className="text-white text-base font-normal leading-[14px]">
                      {msToTime(
                        selectedRoom?.lvlInfo.find((lvl) => lvl.lvl === currentRoom?.lvl! + 1)?.buildingTimeMs! +
                          preventTimeBug
                      )}
                    </p>
                  </div>
                  <AnimatePresence>
                    {isVisible && (
                      <motion.div
                        className="absolute -top-14 p-2 overflow-hidden flex-shrink-0 z-[999] bg-red-700"
                        style={{
                          background: 'radial-gradient(circle, rgb(167 0 0 / 80%) 50%, rgba(250, 186, 60, 0) 100%)',
                        }}
                        variants={variants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        transition={{ duration: 0.2 }}
                      >
                        {/* Верхня Рамка */}
                        <div
                          className="absolute top-0 left-0 w-full h-[2px] pointer-events-none"
                          style={{
                            background:
                              'radial-gradient(circle, rgba(255, 255, 255, 0.9) 0%, rgba(250, 186, 60, 0) 70%)',
                          }}
                        ></div>

                        {/* Нижня Рамка */}
                        <div
                          className="absolute bottom-0 left-0 w-full h-[2px] pointer-events-none"
                          style={{
                            background:
                              'radial-gradient(circle, rgba(255, 255, 255, 0.9) 0%, rgba(250, 186, 60, 0) 70%)',
                          }}
                        ></div>

                        {/* Вміст Елемента */}
                        <div>
                          <p className="text-white">Builders are busy</p>
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </PopupButton>
            </div>
          </>
        )}
      </PopupWindow>
      {(showKitsuneOffer || showStoneOffer) && (
        <div className="fixed inset-0 min-w-full min-h-full z-[55]">
          {/* Чорний фоновий шар */}

          <div className="absolute inset-0 bg-gradient-to-b from-[rgba(0,0,0,0.9)] via-[rgba(0,0,0,0.7)] to-[rgba(0,0,0,0.9)] bg-opacity-50 z-20 pointer-events-none"></div>

          {/* Компонент Offer, який має бути поверх чорного фону */}
          <div className="absolute inset-0 z-30 flex items-center justify-center">
            {showKitsuneOffer ? (
              <Offer
                onClose={() => {
                  setStoneShowOffer(false);
                  setKitsuneShowOffer(false);
                }}
                onClick={() => confirmPurchase({ offerId: mappedKitsuneStoreOffer.id })}
                offerType={mappedKitsuneStoreOffer.offerType}
                id={mappedKitsuneStoreOffer.id}
                rankPoints={defineCountString(Math.floor(Number(mappedKitsuneStoreOffer.price) / 10))}
                resourceList={mappedKitsuneStoreOffer.resourceList}
                layout={mappedKitsuneStoreOffer.layout}
                type={mappedKitsuneStoreOffer.type}
                label={mappedKitsuneStoreOffer.label}
                kitsu={selectedAmount.toString()}
                price={mappedKitsuneStoreOffer.price}
                deadline={mappedKitsuneStoreOffer.deadline}
              />
            ) : (
              <Offer
                onClose={() => {
                  setStoneShowOffer(false);
                  setKitsuneShowOffer(false);
                }}
                onEscape={closePopup}
                onClick={() => confirmPurchase({ offerId: stoneOffer.id })}
                offerType={stoneOffer.offerType}
                id={stoneOffer.id}
                rankPoints={defineCountString(Math.floor(Number(stoneOffer.price) / 10))}
                resourceList={stoneOffer.resourceList}
                layout={stoneOffer.layout}
                type={stoneOffer.type}
                label={stoneOffer.label}
                kitsu={stoneOffer.kitsu}
                price={stoneOffer.price}
                deadline={stoneOffer.deadline}
                currentAmount={currentStone}
                needAmount={createCost}
              />
            )}
          </div>

          <div className={`absolute inset-0  ${selectedOffer ? 'z-29' : ''} flex items-center justify-center`}></div>
        </div>
      )}
      <SuccessOverlay isSuccess={isSuccessPurchase} />
    </div>
  );
};
