import { FooterSelector, HeaderSelector } from './components';
import { Outlet } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../app/store';
import { useEffect, useRef, useState } from 'react';
import { useSessionStorage } from '@uidotdev/usehooks';
import { AnimatePresence } from 'framer-motion';
import { useUtils } from '../utils/navigateTo';
import AnimationClick from '../components/animation/AnimationClick';
import { setRipple } from '../app/features/rippleSlice';

const PAGES_WITHOUT_FOOTER = ['/tutorial', '/battle'];
const PAGES_WITH_HEADER = ['/home', '/play', '/farm', '/room', '/', '/island'];
export const Layout = () => {
  const { activeDiv } = useUtils();
  const game = useRef<HTMLDivElement>(null);
  const [renderKey, setRenderKey] = useState(0);
  const dispatch = useDispatch<AppDispatch>();

  const wsStatus = useSelector((state: RootState) => state.webSocket.status)

  useEffect(() => {}, [activeDiv]);

  useEffect(() => {
    if (wsStatus === 'connected') {
      setRenderKey((prevKey) => prevKey + 1);
    }
  }, [wsStatus]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (game.current && game.current.innerHTML.trim() === '') {
        alert('Empty.. Try reload game');
        setRenderKey((prevKey) => prevKey + 1);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      dispatch(setRipple({ x: e.clientX, y: e.clientY }));
    };

    window.addEventListener('click', handleClick);
    return () => window.removeEventListener('click', handleClick);
  }, []);

  return (
    <div ref={game} key={renderKey}>
      <AnimationClick />
      <AnimatePresence mode="wait">
        {PAGES_WITH_HEADER.includes(activeDiv) && (
          <HeaderSelector key={`header-${activeDiv}`} currentPage={activeDiv} />
        )}
      </AnimatePresence>

      <div className="max-w-[550px]">
        <Outlet />
      </div>

      <AnimatePresence mode="wait">
        {!PAGES_WITHOUT_FOOTER.includes(activeDiv) && (
          <FooterSelector key={`footer-${activeDiv}`} currentPage={activeDiv} />
        )}
      </AnimatePresence>
    </div>
  );
};
