import { useEffect, useMemo, useState } from 'react';
import { PopupButton } from '../../components/PopupButton';
import { CardProps } from '../../interfaces/card';
import { useTelegram } from '../../hooks/useTelegram';
import { Card } from '../../components/Card';
import {
  CardsAtDeck,
  getOverallStats,
  getRemovePrice,
  getUpgradePrice,
  removeCard,
  updateCard,
  upgradeCard,
} from '../../endpoints/dungeonEndpoints';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { useSoundService } from '../../utils/soundService';
import { useTranslation } from 'react-i18next';
import CoinImage from '../../components/CoinImage';
import { CoinsBalance } from '../../layout/components/HeaderCave/components';
import { usePurchase } from '../../hooks/usePurchase';
import { SuccessOverlay } from '../../components/SuccessOverlay';
import BattleCardTooltip from '../../components/BattleCardTooltip';
import { Artefacts, FullArtefacts } from '../../endpoints/mock';
import { mapToFullArtefacts } from '../../utils/artefactsParser';

export const Tavern = ({
  cardsAtDeck,
  closeDeck,
  gold,
  updateSomeStates,
}: {
  cardsAtDeck: CardsAtDeck[];
  closeDeck: () => void;
  gold: number;
  updateSomeStates: any;
}) => {
  const actualSaves = useSelector((state: RootState) => state.battleSave.battleSave.save);
  const [zoomedCard, setZoomedCard] = useState({} as CardProps);
  const [upgradeCount, setUpgradeCount] = useState(0);
  const [price, setPrice] = useState(-1);
  const { userId } = useTelegram();
  const { playSound } = useSoundService();
  const [isAnimating, setIsAnimating] = useState(false);
  const [cards, setCards] = useState<{ id: number; lvl: number; uid: string }[]>([]);
  const { t } = useTranslation();
  const [notEnoughBalance, setNotEnoughBalance] = useState(false);
  const [timeoutId, setTimeoutId] = useState<ReturnType<typeof setTimeout> | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const { selectedOffer, isPurchaseLoading, isSuccessPurchase, confirmPurchase, resetPurchase } = usePurchase();
  const removeCardOfferId = 35;

  const settings = useSelector((state: RootState) => state.settings.settings);
  const appConfig = useSelector((state: RootState) => state.appConfig.configs);

  const mappedArtefacts = useMemo(() => {
    if (!cardsAtDeck || !appConfig) return [];

    if (!appConfig.skills) return [];

    const configArtefacts = appConfig.skills.variables;
    const cards = cardsAtDeck.map((card) => {
      return { id: card.cardId, uid: card.cardUid };
    });
    return mapToFullArtefacts(cards as unknown as Artefacts[], configArtefacts);
  }, [cardsAtDeck, appConfig]);

  const fiteredCardsAtDeck = useMemo(() => {
    if (!mappedArtefacts) return cardsAtDeck;

    const filtered = cardsAtDeck.filter((card) => !mappedArtefacts.some((artefact) => artefact.id === card.cardId));
    return filtered;
  }, [mappedArtefacts]);

  useEffect(() => {
    const a = async () => {
      const result = await getRemovePrice({
        stageId: actualSaves?.currentStage!,
        clientId: userId,
        dungeonId: actualSaves?.dungeonId!,
      });

      if (result) {
        setPrice(result?.price);
        setUpgradeCount(result?.price / 10);
      }
    };
    a();
  }, []);

  const handleOpenRemove = (card: CardProps) => {
    const getUpdateCost = async () => {
      const result = await getRemovePrice({
        stageId: actualSaves?.currentStage!,
        clientId: userId,
        dungeonId: actualSaves?.dungeonId!,
      });

      if (result) {
        setPrice(result?.price);
      }
    };
    getUpdateCost();
    setZoomedCard(card);
  };

  const handleCloseRemove = () => {
    setZoomedCard({} as CardProps);
  };

  const handleRemoveCard = async (card: { id: number; lvl: number; uid: string }) => {
    setLoading(true);
    try {
      setIsAnimating(true); // Почати анімацію
      const result = await removeCard({
        clientId: userId,
        cardUid: card.uid,
        dungeonId: actualSaves?.dungeonId!,
        stageId: actualSaves?.currentStage!,
      });

      setTimeout(() => {
        setIsAnimating(false);
        handleCloseRemove();
        setUpgradeCount((prev) => prev + 1);
        updateSomeStates({
          gold: result.gold,
          currentMana: result.currentMana,
          currentHp: result.currentHp,
          cardsAtDeck: result.cardsAtDeck,
        });
        setLoading(false);
      }, 1000);
    } catch (e) {
      console.error('Error updating card:', e);
      setLoading(false);
    }
  };

  useEffect(() => {
    setCards(
      fiteredCardsAtDeck.map((card) => {
        return { id: card.cardId, lvl: card.stars, uid: card.cardUid };
      })
    );
  }, [fiteredCardsAtDeck]);

  const handleBuyRemoveCard = (gold: number, price: number) => {
    if (gold >= price) {
      handleRemoveCard({
        id: zoomedCard.id,
        lvl: zoomedCard.lvl,
        uid: zoomedCard.uid,
      });
      playSound('itemDelete');
    } else {
      playSound('button');
      if (timeoutId) {
        setTimeoutId(null);
      }
      setTimeoutId(setTimeout(() => setNotEnoughBalance(false), 2000));
      setNotEnoughBalance(true);
    }
  };

  const handleBuyRemoveCardForStars = (cardUid: string) => {
    playSound('button');

    confirmPurchase({
      offerId: removeCardOfferId,
      stageId: actualSaves?.currentStage,
      cardUid,
      dungeonId: actualSaves?.dungeonId,
    });
  };

  const getStats = async () => {
    const response = await getOverallStats({
      clientId: userId,
    });

    updateSomeStates({
      gold: response.gold,
      currentMana: response.currentMana,
      currentHp: response.currentHp,
      cardsAtDeck: response.cardsAtDeck,
    });
  };
  useEffect(() => {
    if (isSuccessPurchase) {
      playSound('questsReward');
      setTimeout(() => {
        setTimeout(() => {
          getStats();
        }, 0);
      }, 1500);
      handleCloseRemove();
    }
  }, [isSuccessPurchase]);

  return (
    <div>
      <div className="w-full h-full fixed top-0 left-0 bottom-0 max-w-[550px] mx-auto right-0 z-[41]">
        <div className="absolute top-[8px] right-[17px] rounded-[6px] z-[31]">
          <CoinsBalance goldAmount={gold} />
        </div>
        <div className="w-full h-full absolute bg-black opacity-60"></div>
        <div className="absolute top-12 my-auto h-[61%] inset-x-4 bottom-16 bg-[#554837] border border-[#18191a] rounded-[2px] p-[2px]">
          <button
            className="absolute z-10 w-11 h-11 p-1 -top-3 -right-3 flex items-center justify-center"
            onClick={closeDeck}
          >
            <img src={require('../../assets/images/shop-modal-close.png')} className="w-8 h-8" alt="" />
          </button>
          <div className=" w-full h-full bg-[#242520] border border-[#18191a] rounded-[2px] p-3 shadow-inner-sm-white">
            <div className="relative w-full h-full bg-[#201b18] rounded-[1px] p-3 pt-7 shadow-inner-sm-black">
              <div className="absolute top-[-28px] left-0 right-0 flex justify-self-center">
                <div className="min-w-[120px] bg-[#847a70] border border-[#18191a] rounded-[2px] p-[1px] mx-auto">
                  <div
                    className="bg-[#351d1e] text-center text-white text-xl p-[7px] leading-none rounded-[1px] border
                    border-[#18191a] shadow-inner-sm-black"
                  >
                    Abyss
                  </div>
                </div>
              </div>
              <div className="flex justify-center gap-x-4 mb-4">
                <div className="text-center text-[#a49a7c] text-sm font-light leading-[14px]">
                  Select the card to abyss. Current price:
                  <span className="text-white"> {upgradeCount === 0 ? 'Free' : upgradeCount * 10}</span>
                </div>
              </div>

              <div className="w-full h-px bg-[#584d3c]"></div>

              <div className={`overflow-x-hidden overflow-y-auto absolute left-3 right-3 bottom-0 top-[80px]`}>
                {cards.length ? (
                  <div className="grid grid-cols-3 gap-7 mb-3">
                    {cards.map((card, index) => (
                      <div onClick={() => handleOpenRemove({ ...card })} key={index}>
                        <Card id={card.id} lvl={card.lvl} uid={card.uid} hidden={false} triggerRemoveCanvas={false} />
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="flex items-center justify-center h-full w-full text-2xl text-white pb-12">Empty</div>
                )}
              </div>
            </div>
          </div>
        </div>

        {zoomedCard && Object.keys(zoomedCard).length !== 0 && price >= 0 && (
          <div>
            <div className="bg-black opacity-60 absolute top-0 left-0 right-0 bottom-0 w-full h-full z-10"></div>
            <div className="absolute z-10 top-0 left-0 right-0 bottom-0 w-full h-full flex items-center justify-center gap-2">
              <div className="flex flex-col gap-4 items-center justify-center relative w-[115px] ">
                {settings.tooltips ? <BattleCardTooltip cardId={zoomedCard.id} type="shop" /> : null}
                <Card
                  id={zoomedCard.id}
                  lvl={zoomedCard.lvl}
                  uid={zoomedCard.uid}
                  hidden={false}
                  triggerRemoveCanvas={false}
                />
                <div className="flex justify-center items-start gap-[96px] pt-[43px]">
                  <PopupButton
                    type={'red'}
                    onClick={() => {
                      handleCloseRemove();
                      playSound('button');
                    }}
                    height={'30px'}
                    width={'90px'}
                  >
                    <p className="flex justify-center items-center pt-[2px] w-full h-full">Close</p>
                  </PopupButton>
                  <div className="relative flex flex-col gap-2">
                    <p className="absolute w-full top-[-28px] text-center text-white font-medium text-stroke-small">
                      Delete
                    </p>
                    <PopupButton
                      type={!loading ? (price === 0 ? 'blue' : gold >= price ? 'green' : 'gray') : 'gray'}
                      disabled={loading}
                      onClick={() => handleBuyRemoveCard(gold, price)}
                      height={'30px'}
                      width={'90px'}
                    >
                      <div className="flex items-center justify-center px-2 gap-1 w-full h-[20px]">
                        <CoinImage width={20} height={20} />
                        <p className="text-white text-base font-bold ">{price === 0 ? 'Free' : price}</p>
                      </div>
                    </PopupButton>
                    {price === 0 ? null : (
                      <PopupButton
                        type="gold"
                        onClick={() => handleBuyRemoveCardForStars(zoomedCard.uid)}
                        height={'30px'}
                        width={'90px'}
                        disabled={loading}
                      >
                        <div
                          className={` absolute top-0 left-0 flex items-center w-[4em] h-[4em] bg-[url("./assets/images/shop/offer/union.png")] bg-cover bg-no-repeat text-[10px] -translate-x-[30%] -translate-y-[20%] `}
                        >
                          <div
                            className={`flex flex-col w-[70%] items-center justify-center bg-[#e49e48] aspect-square rounded-[100%] shadow-[inset_0px_0px_5px_0px_rgba(0,0,0,0.75)] ml-[1px] translate-x-[21%] -translate-y-[2%] `}
                          >
                            <img
                              src={require('../../assets/images/shop/offer/notify.png')}
                              className={`w-[3em] aspect-square -translate-y-[5%] `}
                              alt=""
                            />
                            <p>4</p>
                          </div>
                        </div>
                        <div className="flex items-center justify-center px-2 gap-1 w-full h-[20px]">
                          <img
                            src={require('../../assets/images/shop/offer/offer-star.png')}
                            alt=""
                            className="w-[20px] h-[20px]"
                          />
                          <p className="text-white text-base  font-bold">40</p>
                        </div>
                      </PopupButton>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {notEnoughBalance && (
          <div className="absolute bottom-[100px] left-1/2 -translate-x-1/2 bg-[rgba(120,22,22,0.8)] text-white py-2.5 px-4 z-[31] rounded flex items-center text-center leading-[1.2] whitespace-nowrap">
            {t('notEnoughBalance')}
          </div>
        )}
        {/* <SuccessOverlay isSuccess={isSuccessPurchase} /> */}
      </div>
    </div>
  );
};
